/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
    description,
    lang = 'en',
    meta,
    title,
    url,
    image,
    author,
    twitterUsername,
}: {
    description?: string;
    lang?: string;
    meta?: any;
    title?: string;
    url?: string;
    image?: string;
    author?: string;
    twitterUsername?: string;
}) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        image
                        url
                        twitterUsername
                    }
                }
            }
        `,
    );

    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title || site.siteMetadata.title}
            titleTemplate={`${title || site.siteMetadata.title} | %s `}
            // meta={[
            //     {
            //         name: `description`,
            //         content: metaDescription,
            //     },
            //     {
            //         property: `og:title`,
            //         content: title,
            //     },
            //     {
            //         property: `og:description`,
            //         content: metaDescription,
            //     },
            //     {
            //         property: `og:type`,
            //         content: `website`,
            //     },
            //     {
            //         name: `twitter:card`,
            //         content: `summary`,
            //     },
            //     {
            //         name: `twitter:creator`,
            //         content: site.siteMetadata.author,
            //     },
            //     {
            //         name: `twitter:title`,
            //         content: title,
            //     },
            //     {
            //         name: `twitter:description`,
            //         content: metaDescription,
            //     },
            // ].concat(meta)}
        >
            <title>{title}</title>
            <link rel="canonical" href={url || site.siteMetadata.url} />
            <meta name="description" content={description || site.siteMetadata.description} />
            <meta name="image" content={image || site.siteMetadata.image} />

            <meta property="og:url" content={url || site.siteMetadata.url} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={title || site.siteMetadata.title} />
            <meta property="og:description" content={description || site.siteMetadata.description} />
            <meta property="og:image" content={image || site.siteMetadata.image} />
            <meta name="twitter:creator" content={twitterUsername || site.siteMetadata.twitterUsername} />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content={twitterUsername} />
            <meta name="twitter:creator" content={author || site.siteMetadata.author} />
            <meta name="twitter:title" content={title || site.siteMetadata.title} />
            <meta name="twitter:description" content={description || site.siteMetadata.description} />
            <meta name="twitter:image" content={image || site.siteMetadata.image} />
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO;
